<template>
  <div id="app">
    <nav-bar/>
    <aside-menu :menu="menu" @menu-click="menuClick"/>
    <router-view/>
    <footer-bar/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'

export default {
  name: 'home',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  computed: {
    menu () {
      return [
        'General',
        [
          {
            to: '/',
            icon: 'monitor',
            label: 'Dashboard'
          }
        ],
        'Édition',
        [
          // {
          //   to: '/layout',
          //   label: 'Disposition',
          //   icon: 'square-edit-outline'
          // },
          {
            to: '/filters',
            label: 'Filtres',
            icon: 'square-edit-outline'
          },
          {
            to: '/pictos',
            label: 'Pictos',
            icon: 'square-edit-outline'
          },
          {
            to: '/prestas',
            label: 'Prestations',
            icon: 'square-edit-outline'
          },
          {
            to: '/translations',
            label: 'Traductions',
            icon: 'square-edit-outline'
          }
          // {
          //   to: '/tables',
          //   label: 'Tables',
          //   icon: 'table',
          //   updateMark: true
          // },
          // {
          //   to: '/forms',
          //   label: 'Forms',
          //   icon: 'square-edit-outline'
          // },
          // {
          //   to: '/profile',
          //   label: 'Profile',
          //   icon: 'account-circle'
          // },
          // {
          //   label: 'Submenus',
          //   subLabel: 'Submenus Example',
          //   icon: 'view-list',
          //   menu: [
          //     {
          //       href: '#void',
          //       label: 'Sub-item One'
          //     },
          //     {
          //       href: '#void',
          //       label: 'Sub-item Two'
          //     }
          //   ]
          // }
        ]
      ]
    }
  },
  created () {
    this.$store.commit('user', {
      name: 'John Doe',
      email: 'john@example.com',
      avatar: 'https://avatars.dicebear.com/v2/gridy/John-Doe.svg'
    })
  },
  methods: {
    menuClick (item) {
      if (item.action && item.action === 'dark-mode-toggle') {
        this.$store.commit('darkModeToggle')
      }
    }
  }
}
</script>
