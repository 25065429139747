<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon"/>
      </a>
      <!-- <div class="navbar-item has-control no-left-space-touch">
        <div class="control">
          <input class="input" placeholder="Search">
        </div>
      </div> -->
    </div>
    <div class="navbar-brand is-right">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default"/>
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
      <div class="navbar-end">
        <!-- <nav-bar-menu class="has-divider has-user-avatar">
          <user-avatar/>
          <div class="is-user-name">
            <span>{{ userName }}</span>
          </div>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/profile" class="navbar-item" exact-active-class="is-active">
              <b-icon icon="account" custom-size="default"></b-icon>
              <span>Mes paramètres</span>
            </router-link>
            <hr class="navbar-divider">
            <a href="/logout" class="navbar-item">
              <b-icon icon="logout" custom-size="default"></b-icon>
              <span>Déconnection</span>
            </a>
          </div>
        </nav-bar-menu> -->
        <a class="navbar-item has-divider is-desktop-icon-only" title="Dark mode" @click="darkModeToggle">
          <b-icon :icon="darkModeToggleIcon" custom-size="default"/>
          <span>Dark mode</span>
        </a>
        <a href="/logout" class="navbar-item is-desktop-icon-only" title="Déconnection" @click="logout">
          <b-icon icon="logout" custom-size="default"/>
          <span>Déconnection</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
// import NavBarMenu from '@/components/NavBarMenu'
// import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'NavBar',
  components: {
    // UserAvatar,
    // NavBarMenu
  },
  data () {
    return {
      isMenuNavBarActive: false
    }
  },
  computed: {
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    darkModeToggleIcon () {
      return this.isDarkModeActive ? 'white-balance-sunny' : 'weather-night'
    },
    ...mapState([
      'isNavBarVisible',
      'isAsideMobileExpanded',
      'isDarkModeActive',
      'userName'
    ])
  },
  mounted () {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false
    })
  },
  methods: {
    menuToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    darkModeToggle () {
      this.$store.commit('darkModeToggle')
    },
    logout () {
      this.$buefy.snackbar.open({
        message: 'Déconnection',
        queue: false
      })
    }
  }
}
</script>
