import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Dashboard'
    },
    path: '/',
    name: 'home',
    component: Home
  },
  // {
  //   meta: {
  //     title: 'Options'
  //   },
  //   path: '/options',
  //   name: 'options',
  //   component: () => import(/* webpackChunkName: "forms" */ '../views/Options.vue')
  // },
  {
    meta: {
      title: 'Disposition'
    },
    path: '/layout',
    name: 'layout',
    component: () => import(/* webpackChunkName: "forms" */ '../views/Layout.vue')
  },
  {
    meta: {
      title: 'Filtres'
    },
    path: '/filters',
    name: 'filters',
    component: () => import(/* webpackChunkName: "forms" */ '../views/Filters.vue')
  },
  {
    meta: {
      title: 'Pictos'
    },
    path: '/pictos',
    name: 'pictos',
    component: () => import(/* webpackChunkName: "forms" */ '../views/Pictos.vue')
  },
  {
    meta: {
      title: 'Prestations'
    },
    path: '/prestas',
    name: 'prestas',
    component: () => import(/* webpackChunkName: "forms" */ '../views/Prestas.vue')
  },
  {
    meta: {
      title: 'Traductions'
    },
    path: '/translations',
    name: 'translations',
    component: () => import(/* webpackChunkName: "forms" */ '../views/Translations.vue')
  }
  // {
  //   meta: {
  //     title: 'Tables'
  //   },
  //   path: '/tables',
  //   name: 'tables',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "tables" */ '../views/Tables.vue')
  // },
  // {
  //   meta: {
  //     title: 'Forms'
  //   },
  //   path: '/forms',
  //   name: 'forms',
  //   component: () => import(/* webpackChunkName: "forms" */ '../views/Forms.vue')
  // },
  // {
  //   meta: {
  //     title: 'Profile'
  //   },
  //   path: '/profile',
  //   name: 'profile',
  //   component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  // },
  // {
  //   meta: {
  //     title: 'New client'
  //   },
  //   path: '/client/new',
  //   name: 'client.new',
  //   component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue')
  // },
  // {
  //   meta: {
  //     title: 'Edit client'
  //   },
  //   path: '/client/:id',
  //   name: 'client.edit',
  //   component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue'),
  //   props: true
  // }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
